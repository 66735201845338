<template>
  <div class="icon-system">
    <examine :info="myInfo"></examine>
  </div>
</template>
<script>
import examine from './components/examine.vue';
export default {
  name: "IconSystem",
  components: {
    examine,
  },
  data() {
    return {
      myInfo: {
        title: '人事规章',
        type: 'RUL',
      }
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
 .icon-system {
}
</style>
